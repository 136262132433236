export type Color = keyof typeof colors;

export const colors = {
  primary: '#3CA6B9',
  primary1: '#3CA6B9',
  primary3: '#3CA6B9',
  primary5: '#3CA6B9BB',
  primary6: '#3CA6B9',

  black1: '#F2F2F5',
  black2: '#CECED9',
  black3: '#B6B6BF',
  black4: '#9D9DA6',
  black5: '#85858C',
  black6: '#5C5C5C',
  black7: '#B0B0BA',
  black8: '#3D3D40',
  black10: '#0D0D0D',

  blue1: '#EFF8F9',
  blue2: '#D8EDF1',
  blue3: '#B1DBE3',
  blue4: '#8ACAD5',
  blue5: '#63B8C7',
  blue6: '#3CA6B9',
  blue7: '#3490A1',
  blue8: '#2C7987',
  blue9: '#24626E',
  blue10: '#1B4C54',

  Cyan7: '#08979C',

  red5: '#FF4D4F',
  red6: '#F5222D',

  orange6: '#FA8C16',

  colorWhite: '#FFFFFF',

  defaultColor: '#0D0D0D',
  defaultBorderColor: '#D9D9D9',
  defaultBg: '#FFFFFF',

  background: '#EFF8F9',
  darkBackground: '#0C0D0E',
  colorItemText: '#B3B3B3',
  colorTextLightSolid: '#FFFFFF',
  colorTextPlaceholder: '#5C5C5C',
  colorIcon: '#6E6E6E',
  borderColor: '#D9D9D9',

  //App Colors
  black: '#000',
  white: '#FFF',

  primary50: '#FCFEFF',
  primary100: '#F5FAFF',
  primary300: '#A3D3FF',
  primary500: '#0086FF',
  primary600: '#006BCC',
  primary700: '#005099',
  primary800: '#003666',
  primary900: '#001B33',

  success: '#50B17F',
  successAccent: '#E9FDF2',
  error: '#EA332D',
  errorAccent: '#FDF6E9',
  warning: '#F2AB3C',
  warningAccent: '#FBE9E9',
  progress: '#D87A16',

  //secondary
  secondary: '#050505',
  secondary50: '#F1F2FC',
  secondary100: '#DDDEE8',
  secondary200: '#CCCDD6',
  secondary300: '#B6B7BF',
  secondary400: '#9B9CA3',
  secondary500: '#7C7C82',
  secondary600: '#37373C',
  secondary700: '#252527',
  secondary800: '#181819',
  secondary900: '#050505',
};

export const breakpoints = {
  screenXS: 395,
  screenSM: 600,
  screenMD: 960,
  screenLG: 1280,
  screenXL: 1440,
  screenXXL: 1920,
};
