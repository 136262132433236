import React from 'react';
import { Typography } from 'antd';
import { TextProps } from 'antd/es/typography/Text';
import { Color, colors } from '../utils';

const { Text: AntText, Paragraph: AntParagraph } = Typography;

export type Variant =
  | 'baseNormal'
  | 'baseMedium'
  | 'baseItalic'
  | 'baseStrong'
  | 'baseUnderline'
  | 'smNormal'
  | 'smMedium'
  | 'smStrong'
  | 'smUnderline'
  | 'lgNormal'
  | 'lgMedium'
  | 'lgStrong'
  | 'lgUnderline'
  | 'xlNormal'
  | 'xlMedium'
  | 'xlStrong'
  | 'xlUnderline'
  | 'heading1'
  | 'heading2'
  | 'heading3'
  | 'heading4'
  | 'heading5';

interface textProps extends TextProps {
  variant?: Variant;
  fontSize?: number | string;
  fontWeight?: number | string;
  fontFamily?: string;
  color?: Color;
  center?: boolean;
  lineHeight?: string | number;
}

export const HeaderText = (props: textProps) => {
  const { fontSize = 22, fontWeight = 700, color = 'white', ...rest } = props;

  return (
    <AntText
      {...rest}
      style={{ color: colors[color], fontWeight, fontSize, ...props.style }}
    >
      {props.children}
    </AntText>
  );
};

export const Text = (props: textProps) => {
  const {
    variant = 'baseNormal',
    fontSize,
    center = false,
    fontWeight,
    lineHeight,
    color = 'black10',
    ...rest
  } = props;
  const styles = getVariantStyles(variant);

  return (
    <AntText
      {...rest}
      style={{
        ...styles,
        color: colors[color],
        alignItems: center ? 'center' : 'left',
        ...(!!fontWeight && { fontWeight }),
        ...(!!fontSize && { fontSize }),
        ...(!!lineHeight && { lineHeight: `${lineHeight}px` }),
        ...props.style,
      }}
    >
      {props.children}
    </AntText>
  );
};

export const Paragraph = (props: textProps) => {
  const {
    variant = 'baseNormal',
    fontSize,
    center = false,
    fontWeight,
    color = 'black10',
    ...rest
  } = props;
  const styles = getVariantStyles(variant);

  return (
    <AntParagraph
      {...rest}
      style={{
        ...styles,
        color: colors[color],
        alignItems: center ? 'center' : 'left',
        ...(!!fontWeight && { fontWeight }),
        ...(!!fontSize && { fontSize }),
        ...props.style,
      }}
    >
      {props.children}
    </AntParagraph>
  );
};

export const EllipsisMiddle: React.FC<{
  suffixCount: number;
  children: string;
  textProps?: any;
}> = ({ suffixCount, children, textProps }) => {
  const start = children?.slice(0, children.length - suffixCount).trim();
  const suffix = children?.slice(-suffixCount).trim();
  return (
    <Text
      {...textProps}
      style={{ maxWidth: '90%', ...textProps?.style }}
      ellipsis={{ suffix }}
    >
      {start}
    </Text>
  );
};

export const TruncateString: React.FC<{
  count: number;
  children: string;
  textProps?: any;
}> = ({ count, children, textProps }) => {
  return (
    <Text
      {...textProps}
      style={{ maxWidth: '90%', ...textProps?.style, maxLines: 1 }}
    >
      {`${children.slice(0, count)}${children.length > count ? '...' : ''}`}
    </Text>
  );
};

export const renderParagraph = (text: string) =>
  text.split('\n\n').map((para) => <Paragraph>{para}</Paragraph>);

export const getVariantStyles = (variant: Variant) => {
  switch (variant) {
    case 'baseNormal':
      return {
        fontSize: 14,
        fontWeight: 400,
        lineHeight: '22px',
      };
    case 'baseMedium':
      return {
        fontSize: 14,
        fontWeight: 500,
        lineHeight: '22px',
      };
    case 'baseItalic':
      return {
        fontSize: 14,
        fontWeight: 500,
        lineHeight: '22px',
        fontFamily: 'Poppins-Italic',
      };
    case 'baseStrong':
      return {
        fontSize: 14,
        fontWeight: 600,
        lineHeight: '22px',
      };
    case 'baseUnderline':
      return {
        fontSize: 14,
        fontWeight: 400,
        lineHeight: '22px',
        textDecorationLine: 'underline',
      };
    case 'smNormal':
      return {
        fontSize: 12,
        fontWeight: 400,
        lineHeight: '20px',
      };
    case 'smMedium':
      return {
        fontSize: 12,
        fontWeight: 500,
        lineHeight: '20px',
      };
    case 'smStrong':
      return {
        fontSize: 12,
        fontWeight: 600,
        lineHeight: '20px',
      };
    case 'smUnderline':
      return {
        fontSize: 12,
        fontWeight: 400,
        lineHeight: '20px',
        textDecorationLine: 'underline',
      };
    case 'lgNormal':
      return {
        fontSize: 16,
        fontWeight: 400,
        lineHeight: '24px',
      };
    case 'lgMedium':
      return {
        fontSize: 16,
        fontWeight: 500,
        lineHeight: '24px',
      };
    case 'lgStrong':
      return {
        fontSize: 16,
        fontWeight: 600,
        lineHeight: '24px',
      };
    case 'lgUnderline':
      return {
        fontSize: 16,
        fontWeight: 400,
        lineHeight: '24px',
        textDecorationLine: 'underline',
      };
    case 'xlNormal':
      return {
        fontSize: 20,
        fontWeight: 400,
        lineHeight: '28px',
      };
    case 'xlMedium':
      return {
        fontSize: 20,
        fontWeight: 500,
        lineHeight: '28px',
      };
    case 'xlStrong':
      return {
        fontSize: 20,
        fontWeight: 600,
        lineHeight: '28px',
      };
    case 'xlUnderline':
      return {
        fontSize: 20,
        fontWeight: 400,
        lineHeight: '28px',
        textDecorationLine: 'underline',
      };
    case 'heading1':
      return {
        fontSize: 38,
        fontWeight: 600,
        lineHeight: '46px',
      };
    case 'heading2':
      return {
        fontSize: 30,
        fontWeight: 600,
        lineHeight: '38px',
      };
    case 'heading3':
      return {
        fontSize: 24,
        fontWeight: 600,
        lineHeight: '32px',
      };
    case 'heading4':
      return {
        fontSize: 20,
        fontWeight: 600,
        lineHeight: '28px',
      };
    case 'heading5':
      return {
        fontSize: 16,
        fontWeight: 600,
        lineHeight: '24px',
      };
    default:
      return {
        fontSize: 14,
        fontWeight: 400,
        lineHeight: '22px',
      };
  }
};
