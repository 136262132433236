import { gql } from '@apollo/client';

export const LOGIN = gql`
  mutation loginAdmin($loginInput: LoginInput!) {
    loginAdmin(loginInput: $loginInput) {
      accessToken
      refreshToken
    }
  }
`;

export const CREATE_QUESTION = gql`
  mutation createQuestion($questionInput: QuestionInput!) {
    createQuestion(questionInput: $questionInput) {
      id
      name
      image
      price
    }
  }
`;

export const UPDATE_QUESTION = gql`
  mutation updateQuestion($id: String!, $questionInput: UpdateQuestionInput!) {
    updateQuestion(id: $id, questionInput: $questionInput) {
      id
      name
      image
      price
    }
  }
`;

export const DELETE_QUESTION = gql`
  mutation deleteQuestion($id: String!) {
    deleteQuestion(id: $id)
  }
`;

export const UPLOAD_FILE = gql`
  mutation uploadImage($image: Upload!, $featureName: String!) {
    uploadImage(image: $image, featureName: $featureName)
  }
`;
