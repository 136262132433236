import { Card, Col, Row, Table } from 'antd';
import { Text } from '../../../components';
import { useQuery } from '@apollo/client';
import { Query, User, GET_USERS, QueryGetUsersArgs } from '../../../graphql';
import { ColumnsType } from 'antd/es/table';
import { format } from 'date-fns';
import { useState } from 'react';

const Users = () => {
  const [page, setPage] = useState<number>(1);
  const { data, loading } = useQuery<Query, QueryGetUsersArgs>(GET_USERS, {
    variables: { page, take: 10 },
    fetchPolicy: 'network-only',
  });

  const columns: ColumnsType<User> = [
    {
      key: 'firstName',
      title: 'First name',
      dataIndex: 'firstName',
      responsive: ['lg'],
    },
    {
      key: 'lastName',
      title: 'Last name',
      dataIndex: 'lastName',
      responsive: ['lg'],
    },
    {
      key: 'username',
      title: 'Username',
      dataIndex: 'username',
    },
    {
      key: 'score',
      title: 'Score',
      render: (_, record) =>
        record.timedScore + record.roundsScore + record.referalScore,
    },
    {
      key: 'code',
      title: 'Referral code',
      dataIndex: 'code',
    },
    {
      key: 'createdAt',
      title: 'Joined',
      dataIndex: 'createdAt',
      responsive: ['lg'],
      render: (item) => format(new Date(item), 'PP'),
    },
  ];

  return (
    <Card>
      <Row gutter={[0, 12]}>
        <Col span={24}>
          <Text fontSize={20} fontWeight={500}>
            All users
          </Text>
        </Col>
        <Col span={24}>
          <Table
            scroll={{ x: 'max-content' }}
            loading={loading}
            columns={columns}
            dataSource={data?.getUsers?.data}
            rowClassName={'clickable'}
            pagination={{
              pageSize: 10,
              total: data?.getUsers.count,
              current: page,
              onChange: (page) => setPage(page),
              hideOnSinglePage: true,
            }}
          />
        </Col>
      </Row>
    </Card>
  );
};

export default Users;
