import { Col, Row } from 'antd';
import { colors } from '../../utils';
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint';

type LayoutProps = {
  children: React.ReactNode;
  flip?: boolean;
};

type ContentProps = {
  children: React.ReactNode;
};

const Content = ({ children }: ContentProps) => {
  const { sm, lg, xxl } = useBreakpoint();

  const getPadding = () => {
    if (xxl) return '50px 100px';
    if (lg) return '50px';
    if (sm) return '50px 60px';
    return '30px 20px';
  };

  return (
    <Row
      justify={'space-between'}
      style={{ padding: getPadding(), height: '100%' }}
      gutter={[0, 36]}
    >
      <Col span={24} style={{ alignSelf: 'center' }}>
        {children}
      </Col>
    </Row>
  );
};

const AuthLayout = (props: LayoutProps) => {
  return (
    <Row style={{ height: '100vh', textAlign: 'center' }} justify={'center'}>
      <Col
        xs={24}
        md={18}
        lg={12}
        xl={10}
        style={{ backgroundColor: colors.background }}
      >
        <Content children={props.children} />
      </Col>
    </Row>
  );
};

export default AuthLayout;
