import { gql } from '@apollo/client';

export const GET_USERS = gql`
  query getUsers($page: Float, $take: Float) {
    getUsers(page: $page, take: $take) {
      data {
        id
        telegramId
        username
        firstName
        lastName
        code
        timedScore
        roundsScore
        referalScore
        createdAt
        updatedAt
      }
      count
    }
  }
`;

export const GET_QUESTIONS = gql`
  query GetQuestions($page: Float, $take: Float) {
    getQuestions(page: $page, take: $take) {
      data {
        id
        name
        image
        price
      }
      count
    }
  }
`;
