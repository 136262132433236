import React from 'react';
import { Text, PrimaryButton } from '../../components';
import { Input, Row, Form, Col, message } from 'antd';
import { AuthLayout } from './index';
import { useNavigate } from 'react-router-dom';
import { LOGIN, Mutation, MutationLoginAdminArgs } from '../../graphql';
import { useMutation } from '@apollo/client';

type FieldType = {
  email: string;
  password: string;
};

const Login = () => {
  const navigate = useNavigate();
  const [login, { loading }] = useMutation<Mutation, MutationLoginAdminArgs>(
    LOGIN
  );

  const onFinish = async (values: FieldType) => {
    try {
      const res = await login({
        variables: {
          loginInput: {
            email: values.email,
            password: values.password,
          },
        },
      });
      if (res.data?.loginAdmin) {
        localStorage.setItem('token', res.data?.loginAdmin?.accessToken);
        localStorage.setItem(
          'refreshToken',
          res.data?.loginAdmin?.refreshToken
        );
        navigate('/');
      }
    } catch (error) {
      console.log('err login', error);
    }
  };

  return (
    <AuthLayout>
      <Form
        name="basic"
        style={{ width: '100%' }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Row gutter={[0, 32]}>
          <Col span={24}>
            <Row gutter={[0, 4]}>
              <Col span={24}>
                <Text fontSize={24} fontWeight={600}>
                  Nova Game
                </Text>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Row gutter={[0, 12]}>
              <Col span={24}>
                <Form.Item<FieldType>
                  name="email"
                  rules={[{ required: true, message: '' }]}
                >
                  <Input required type="email" placeholder="Email address" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item<FieldType>
                  name="password"
                  rules={[{ required: true, message: '' }]}
                >
                  <Input.Password required placeholder="Password" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <PrimaryButton
                  htmlType="submit"
                  loading={loading}
                  style={{
                    width: '100%',
                    marginTop: '0.5em',
                  }}
                >
                  Log in
                </PrimaryButton>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </AuthLayout>
  );
};

export default Login;
